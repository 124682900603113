import { useStore } from 'reactflow'

const useSelectedNode = () => {
  const selectedNode = useStore(
    (state) => {
      const nodes = Array.from(state.nodeInternals.values())
      return nodes.find((node) => node.selected) || null
    },
    (prevNode, nextNode) =>
      prevNode?.data === nextNode?.data
  )
  return selectedNode
}

export const useAllSelectedNodes = () => {
  const selectedNodes = useStore(
    (state) => {
      const nodes = Array.from(state.nodeInternals.values())
      return nodes.filter((node) => node.selected)
    },
    (prevNodes, nextNodes) =>
      prevNodes.length === nextNodes.length &&
      prevNodes.every((node, index) => node.id === nextNodes[index].id)
  )
  return selectedNodes
}

export default useSelectedNode
